import {Button, Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import portfolioPreview from "../../images/portfolio-preview.svg";
import React, {useCallback, useContext, useRef, useState} from "react";
import "../../styles/default.scss"
import { ModalContext } from "../layout";

const PublicPortfolioHero = ({ portFolioFeatures, onEmailSubmitted  }) => {
    const [, setShowModal] = useContext(ModalContext)
    const openModal = () => setShowModal(true)
    const [imageContainerHeight, setImageContainerHeight] = useState(200)
    const [validationMessage, setValidationMessage] = useState("");
    const inputRef = useRef();

    const measuredRef = useCallback(node => {
        if (node) {
            setImageContainerHeight(node.getBoundingClientRect().width/1.55);
        }
    }, []);

    const NotifyMeForm = () => {
        return (
            <Form style={{ marginTop: 'auto', marginBottom: '2em'}}>
                <InputGroup className="">
                    <Form.Control
                        ref={inputRef}
                        type="email"
                        id="notify_me_email"
                        placeholder="Your Email Address"
                        className="shadow-none outline-none"
                        required
                    />
                    <div>
                        <Button
                            variant="primary"
                            className="h-100 px-4 px-5   py-lg-2 border-0"
                            style={{ color: 'black', background: "#FDB523", borderRadius: '0 .2em .2em 0' }}
                            onClick={(event) => {
                                event.preventDefault()
                                const email = inputRef.current['value'] || document.getElementById("notify_me_email").value
                                if(email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                                    onEmailSubmitted(email);
                                    setValidationMessage("")
                                    openModal()
                                } else setValidationMessage("Please provide a valid email")
                            }}
                        >
                            Notify Me
                        </Button>
                    </div>
                </InputGroup>
                <div style={{ color: "#FDB523", fontSize: '.8em', textAlign: 'left'}}>{validationMessage}</div>
            </Form>
        )
    }

    return (
        <section id="notify-me-email-form">
            <Row
                className="d-none d-sm-flex mx-0 flex-column align-items-center justify-content-center"
                style={{ backgroundColor: '#1F7ABF' }}
            >
                <Col className="col-10 col-lg-9">
                    <Row className="d-flex flex-row justify-content-between mt-5">
                        <Col className="col-5 text-white d-flex flex-column">
                            <h1 className="pb-3">Public Portfolios </h1>
                            <p>
                                Team Coaches offers you a great way to publicly share your portfolio companies.<br/><br/>
                                Please sign-up if you want to have access to our beta test or to be notified when we are
                                launching.
                            </p>
                           <NotifyMeForm />
                        </Col>
                        <Col className="col-5 d-flex flex-column justify-content-center align-content-stretch">
                            <Image
                                fluid
                                rounded
                                src={portfolioPreview}
                                alt="add company info image"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="col-10 col-lg-9"  style={{ marginTop: '4em', paddingBottom: '5em'}}>
                    <Row className="d-flex flex-row justify-content-between">
                        {portFolioFeatures.map(feature => (
                                <Col key={feature.title} className="col-4 text-white d-flex flex-column align-items-center align-items-sm-start">
                                    <Image
                                        fluid
                                        rounded
                                        src={feature.image}
                                        alt="simple portfolio image"
                                        style={{ width: '3em'}}
                                    />
                                    <h5 className="mt-2 mb-4">{feature.title}</h5>
                                    <p style={{ paddingRight: '3em' }} className="remove-p-r-mobile">{feature.description}</p>
                                </Col>
                            )
                        )}
                    </Row>
                </Col>
            </Row>
            <Row className="d-flex d-sm-none mx-0 flex-column">
                <Col className="col-12">
                    <Row className="d-flex flex-row justify-content-between">
                        <Col className="col-12 text-white d-flex flex-column pt-5 px-5" style={{ backgroundColor: '#1F7ABF', paddingBottom: '5em' }}>
                            <h1 className="pb-3">Public Portfolios </h1>
                            <p>
                                Team Coaches offers you a great way to publicly share your portfolio companies.<br/><br/>
                                Please sign-up if you want to have access to our beta test or to be notified when we are
                                launching.
                            </p>
                            <NotifyMeForm />
                        </Col>
                        <Col className="col-12 position-relative px-5" style={{ height: imageContainerHeight}}>
                            <Image
                                id="portfolio-preview-image"
                                ref={measuredRef}
                                fluid
                                rounded
                                src={portfolioPreview}
                                alt="add company info image"
                                className="mx-auto"
                                style={{
                                    width: '80%',
                                    position: 'absolute',
                                    top: '-5.2em',
                                    left: 0,
                                    right: 0,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginBottom: '10em'
                            }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="col-12"  style={{ paddingTop: '4em', paddingBottom: '5em', backgroundColor: '#1F7ABF'}}>
                    <Row className="d-flex flex-row justify-content-between px-4">
                        {portFolioFeatures.map(feature => (
                                <Col  key={feature.title}  className="col-12 text-white d-flex flex-column align-items-center align-items-sm-start">
                                    <Image
                                        fluid
                                        rounded
                                        src={feature.image}
                                        alt={`${feature.title} portfolio image`}
                                        style={{ width: '3em'}}
                                    />
                                    <h5 className="mt-2 mb-4">{feature.title}</h5>
                                    <p>{feature.description}</p>
                                </Col>
                            )
                        )}
                    </Row>
                </Col>
            </Row>
        </section>
    )
}


export default PublicPortfolioHero
