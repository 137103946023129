import React, { useState } from "react"
import {Button, Col, Container, Image, NavLink, Row} from "react-bootstrap"
import addCompanyInfo from "../images/add-company-info.svg"
import configurePortfolio from "../images/configure-public-portfolio.svg"
import simpleIcon from "../images/simple-icon.svg"
import powerAcceleratorIcon from "../images/power-accelerate.svg"
import customizableIcon from "../images/customizable-icon.svg"
import Footer from "../components/footer"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import AppAccordion from "../components/AppAccordion";
import PublicPortfolioHero from "../components/hero/PublicPortfolioHero";
import Layout from "../components/layout";

const faqs = {
    sectionOne: [
        {
            question: "What is Public Portfolio?",
            body: "A Public Portfolio is the best way for your venture to share your portfolio companies. Coaches and Mentors uses to report the companies that they worked with too"
        },
        {
            question: "Who is for Public Portfolio?",
            body: "Public Portfolio is for anyone that works with companies such as Coaches, Mentors, Accelerators, Incubators, Venture Capital"
        },
        {
            question: "How much does it cost?",
            body: "Public Portfolio is free. Please contact us to get access."
        }
    ],
    sectionTwo: [
        {
            question: "Can I upgrade to Team Coaches full functionality afterwards?",
            body: "Of course, Team Coaches allows you to expand your reach and make your community excel. If you want a Demo please reach us booking a demo"
        },
        {
            question: "Who owns the data?",
            body: "You, the data is yours and will never be sold or share."
        },
        {
            question: "Does Public Portfolio support SEO optimization?",
            body: "Yes, that is one of the features that we are working on."
        },
        {
            question: "Can I add my custom domain?",
            body: "Of course, you will be able to white-label Public Portfolio page, in our final version, if interested let us know to enter into our beta testing."
        },
    ]
}

const portFolioFeatures= [
    {
        image: simpleIcon,
        title: "Simple",
        description: "Team Coaches Public Portfolio is easy to use and straightforward to put your portfolio companies out there."
    },
    {
        image: powerAcceleratorIcon,
        title: "Powerful",
        description: " Our platform allows you to add all information required for your companies to get more exposure to the market, with powerful tools to track progress."
    },
    {
        image: customizableIcon,
        title: "Customizable",
        description: "You can customize the Public Portfolio so that you get more SEO and custom URL on your own site"
    }
]

const PublicPortfolios = ({ location }) => {
    const [email, setEmail] = useState("")
    const [isNotifyMeModal, setIsNotifyMeModal] = useState(false)

    return (
        <Layout
            isNotifyMe={isNotifyMeModal}
            notifyMeEmail={email}
            onModalClose={() => {
                setEmail("");
                setIsNotifyMeModal(false)
            }}
        >
            <Seo title="Team Coaches Public Portfolio Page" />
            <NavBar location={location} />
            <Container fluid className="px-0">
                <PublicPortfolioHero
                    portFolioFeatures={portFolioFeatures}
                    onEmailSubmitted={(email) => {
                        setEmail(email);
                        setIsNotifyMeModal(true)
                    }}
                />
                <Row className="mx-0 d-flex flex-column align-items-center justify-content-center">
                    <Col className="text-center col-12 mt-5 mb-5 mx-0 py-4">
                        <h2 className="class-header">How it works</h2>
                    </Col>
                    <Col className="col-12 col-md-10 col-lg-9">
                        <Row className="d-flex flex-row justify-content-between">
                            <Col className="col-12 col-sm-5 d-flex flex-column justify-content-center">
                                <h4>Add Companies Info</h4>
                                <p>
                                    Create new Companies that will be seen in <br /> your Portfolio.
                                    Add as much information as you would like to be seen <br /> in the public company profile.
                                </p>
                            </Col>
                            <Col className="col-12 col-sm-5">
                                <Image
                                    fluid
                                    rounded
                                    src={addCompanyInfo}
                                    alt="add company info image"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-12 col-md-10 col-lg-9">
                        <Row className="d-flex flex-row justify-content-between" style={{ marginTop: '5em'}}>
                            <Col className="order-1 order-sm-0  col-12 col-sm-5">
                                <Image
                                    fluid
                                    rounded
                                    src={configurePortfolio}
                                    alt="configure public portfolio image"
                                />
                            </Col>
                            <Col className="order-0 order-sm-1 col-12 col-sm-5 d-flex flex-column justify-content-center">
                                <h4>Configure Your Public <br /> Portfolio</h4>
                                <p>Add all relevant information about your Venture that<br /> will be shared in Public.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row
                    className="mx-0 d-flex flex-column align-items-center justify-content-center"
                    style={{ paddingBottom: '22em', paddingTop: '5em' }}
                >
                    <Col className="text-center text-white col-12 mt-5 mb-5 mx-0 py-4" style={{ backgroundColor: '#1F7ABF' }}>
                        <h2 className="class-header">Frequently Asked Questions </h2>
                    </Col>
                    <Col className="col-12 col-md-10 col-lg-10 pb-5">
                        <Row className="m-0 d-flex flex-row justify-content-center">
                            <Col className="col-12 col-md-5 col-lg-5 m-0">
                                <AppAccordion items={faqs.sectionOne}/>
                            </Col>
                            <Col className="col-1 hide-on-small-screen" />
                            <Col className="col-12 col-md-5 col-lg-5 m-0">
                                <AppAccordion items={faqs.sectionTwo}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Footer
                    newFooter
                    sideText={"“Your Portfolio to promote your work and investment”"}
                    customButton={() => {
                        return (
                            <NavLink href="#notify-me-email-form" className="">
                                <Button
                                    className="mt-4 mt-md-0 py-2 bg-white fw-bold"
                                    style={{
                                        width: '15em',
                                        borderRadius: '.5em',
                                        color: '#1F7ABF',
                                    }}>
                                    Notify Me
                                </Button>
                            </NavLink>
                        )
                    }}
                />
            </Container>
        </Layout>
    )
}

export default PublicPortfolios
